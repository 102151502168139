* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /* test to remove white bar bounce */
  overflow: hidden;
  height: 100vh;
}

body {
  position: relative;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to bottom, #2c2c2c 40%, black 75%),
    url("./assets/abstract-background.jpg");
  background-blend-mode: saturation;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(4px);
  color: #d6d4e0;
  font-size: 16px;
  /* test to remove white bar bounce */
  min-height: 100%;
  overflow: auto;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 800px) {
  body {
    font-size: 12px;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: medium;
  }
}
