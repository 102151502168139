.card{
    /* position: relative; */
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px 5px;   
    border-radius: 10px;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
    transition: transform 200ms ease in;
    height: 34rem;
    width: 26rem;
    background-color: rgba(35, 35, 35, 0.698);
    transition: ease-in-out .4s;
}

.card:hover{
    transform: scale(1.03);
    
    /* box-shadow: rgba(209, 209, 209, 0.35) 0px 2px 8px 1px;    */

}
.callToAction{
    position: relative;
    display: flex;
    
}

.lowerCard{
    padding: .5rem;
}

.cardImg {
    /* border-radius: 10px; */
    height: 14rem;
    width: 100%;
    object-fit: cover;
    box-shadow: rgba(20, 20, 20, 0.755) 0px 2px 2px 1px;   
}

.buttons{
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    gap: 1rem;
    
}


.card button {
    cursor: pointer;
    text-shadow: 0px  -1px 0px rgba(0,0,0,.5);
    -webkit-box-shadow: 0px 2px 0px rgba(29, 29, 29, 0.886);
    background: -webkit-linear-gradient(rgba(120, 120, 120, 0.829), rgb(28, 28, 28));
    padding: .33rem;
    padding-left: .5rem;
    padding-right: .5rem;
    font-weight: 600;
    border: solid 1px #d6d4e0;
    border-radius: 4px;
    transition: .3s;    
    color: #d6d4e0;
    text-align: center;
}


.card button:hover{
    color: #fff;
    transform: scale(1.1);
    
}


.cardTitle {
    padding: .25rem;
    text-align: center;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.43);

 
}


.techList{
    border-top: solid 1px #d6d4e04e;
    border-bottom: solid 1px #d6d4e04e;
    padding: .5rem;
    padding-bottom: .5rem;
    display: flex;
    justify-content: space-evenly;
    
}

.cardDescription {
    overflow-y: auto;
    text-align: center;
    padding: .25rem;
}

.titles{
    padding: .25rem;
    text-align: center;
    color: #d6d4e0c0;
    
}

.card a, a:visited {
    text-decoration: none;
    color: inherit;
}


@media only screen and (max-width: 800px) {
    .card{

        height: 70vh;
        width: 55vw;
        justify-content: center;
        align-items: center;
    }
    .card:hover{
        transform:none;
    }
    .card p{
        padding-bottom: 5%;
    }
  }

  @media only screen and (max-width: 414px) {
    
    .card{
        width: 75vw;
    }
    .cardTitle {
        font-size: 1.5em;
    }
    .cardImg{
        height: 28vh;
    }
    .card button{
        border-radius: 3px;
        font-size: 14px;
        height: 2.5rem;
        width: 6rem;
    }
    .cardDescription{
        font-size: 15px;
    }

  }

@media only screen and (max-width: 900px) and (orientation: landscape){
    .card{
        height: 65vh;
        width: 80vw;  

    }
    .lowerCard{
        padding: .25rem;
    
    }
    .cardBody{
       display: flex;
       width: 80vw;
    }
    .cardImg{
        /* margin-top: 6%; */
        height: 65vh;
        width: 35vw;
        
    }
    .card button{
        border-radius: 3px;
        font-size: 14px;
        height: 2.5rem;
        width: 6rem;
        
    }
    .card p{
        padding-top: 0;
        margin-bottom: .5rem;
    }
}
@media only screen and (max-width: 380px) {
    .card p{
        font-size: 12px;
    }

}

@media only screen and (max-width: 300px) {
    .card p{
        padding: none;
        font-size: 11px;
    }
    .card button{
        border-radius: 2px;
        font-size: 10px;
        height: 2rem;
        width: 5rem;
    }
}