.header {
    min-height: 10vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    overflow-x: hidden;
    transition: 0.5s;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.header h2{
    
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  letter-spacing: .2em;
  transition: ease-in-out .5s;
  color: #d6d4e0c0;
}


@media only screen and (max-width: 880px){
    .header h2{
        font-size: 1rem;
    }
  }


/* 
  @media only screen and (max-width: 800px) and (orientation: landscape){
    

    
  } */