
.main {
    position: absolute;
    height: 100vh;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.main h1 {
    font-family: 'CGF Locust Resistance', sans-serif;
    letter-spacing: .5rem;
    margin: 0;
    text-align: center;
    font-size: 6.25rem;
    background: -webkit-linear-gradient(rgb(138, 16, 169), rgba(248, 222, 55, 0.829));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;    	
    text-shadow: 4px 3px 0px rgba(234, 118, 254, 0.391);
    /* mix-blend-mode: multiply; */
}



.main h2 {
    padding-top: .25rem;
    padding-bottom: 3.5rem;
    font-weight: 600;
    font-size: 2.8rem;
    text-align: center;
    letter-spacing: .35em;
    transition: ease-in-out .5s;
    text-shadow: 3px 2px 0px rgba(234, 118, 254, 0.437);
}




.main p {
    font-size: 1.25em;
    padding-bottom: 2rem;
    
}

.main span {
    cursor: pointer;
}

.main span:hover {
    cursor: pointer;
    background: -webkit-linear-gradient(rgba(248, 222, 55, 0.829), rgb(138, 16, 169));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.icons {
    
    padding-top: .5rem;
    display: flex;
    gap: 2rem;
    justify-content: space-evenly;
}

.github, .linkedin, .downloadCV{
    color: #d6d4e0;
    height: 2.5rem;
    width: 2.5rem;
    transition: .3s;
}

.icons a:visited {
    text-decoration: none;
    color: inherit;
}

.github:hover, .linkedin:hover, .downloadCV:hover, .arrow:hover{
    cursor: pointer;
    transform: scale(1.25);
}



  @media only screen and (max-width: 1024px){
    .main{
        width:80%
    }
    .main h1{
        font-size: 64px;
        text-shadow: 2px 2px 0px rgba(234, 118, 254, 0.391);
     }
     
     .main h2{
         padding-top: 1rem;
         padding-bottom: 1.5rem;
         font-size: 22px;
         text-shadow: 2px 1px 0px rgba(234, 118, 254, 0.437);
      }
  }


  @media only screen and (max-width: 880px){
    .main{
        width:80%
    }
    .main h1{
       font-size: 54px;
       text-shadow: 2px 2px 0px rgba(234, 118, 254, 0.391);
    }
    
    .main h2{
        padding-top: 0;
        padding-bottom: 1.5rem;
        font-size: 22px;
        text-shadow: 2px 1px 0px rgba(234, 118, 254, 0.437);
     }
     .main h3{
        font-size: 12px;
     }
     .main p{
        font-size: 15px;
        text-align: center;
     }
     .github, .linkedin, .downloadCV{
        height: 2rem;
        width: 2rem;
        transition: .3s;
    }
  }



  @media only screen and (max-width: 900px) and (orientation: landscape){
    .main{
        padding: 1rem;
        width:80%
    }
    .main h1{
        margin-top: .5rem;
       font-size: 58px;
       text-shadow: 2px 2px 0px rgba(234, 118, 254, 0.391);
       
    }
    
    .main h2{
        padding-top: .15rem;
        padding-bottom: 1rem;
        font-size: 22px;
        text-shadow: 2px 1px 0px rgba(234, 118, 254, 0.437);
     }
     .main h3{
        font-size: 10px;
        margin-bottom: 2.5rem;
     }
     .main p{
        font-size: 13px;
        text-align: center;
        padding-bottom: .15rem;
     }
     .github, .linkedin, .downloadCV, .arrow{
        height: 1.5rem;
        width: 1.5rem;
        transition: .3s;
    }

    
  }