.App {
  position: relative;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
  height: 100dvh;
  width: 100%;

  scroll-behavior: smooth;
  /* scroll-padding: 10px; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.App::-webkit-scrollbar {
  display: none;
}

.section1,
.section2,
.section3 {
  height: 100dvh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

.Projects {
  height: 70vh;
  width: 60vw;
}

.cardSwiper {
  width: 100%;
  height: 100%;
}

.swiperSlide {
  -webkit-transform: translateZ(0);
  width: 100%;
  height: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-pagination-bullet {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
  background-color: #d6d4e0c0 !important;
  height: 0.75rem !important;
  width: 0.75rem !important;
}

.swiper-pagination-bullet-active {
  background-color: rgba(254, 100, 249, 0.791) !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #d6d4e0c0 !important;
  transition: ease-in-out 0.5s !important;
}

.swiper-button-next {
  color: #bbb9c484 !important;
}

.swiper-button-prev {
  color: #bbb9c484 !important;
}

.tech {
  color: #d6d4e0;
  height: 1.5rem;
  width: 1.5rem;
  transition: 0.3s;
  display: flex;
}

@media only screen and (max-width: 800px) {
  /* .Projects{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    height: 70vh;

  } */
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .Projects {
    width: 95vw;
    height: 80vh;
  }
  .tech {
    height: 1rem;
    width: 1rem;
  }
  .swiper-pagination-bullet {
    height: 0.6rem !important;
    width: 0.6rem !important;
  }
}

@media only screen and (max-width: 414px) {
  .Projects {
    height: 80vh;
    width: 100vw;
    margin-bottom: 4rem;
  }
  .swiper-pagination-bullet {
    height: 0.6rem !important;
    width: 0.6rem !important;
  }
}
